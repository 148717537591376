import React from "react";
import iconLogo from "../img/iconlogo.svg";

class NavbarBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { addClass: false };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    let width = window.innerWidth;
    if (width < 960) {
      this.setState({ addClass: !this.state.addClass });
      let expandable = document.getElementById("expandable");
      let ham = document.getElementById("ham");
      let navbar = document.getElementById("navbar");
      let navbarNav = document.getElementById("navbar-nav");
      if (this.state.addClass) {
        expandable.classList.remove("expand");
        navbar.classList.remove("display");
        navbarNav.classList.remove("display");
        ham.classList.remove("active");
      } else {
        expandable.classList.add("expand");
        navbar.classList.add("display");
        navbarNav.classList.add("display");
        ham.classList.add("active");
      }
    }
  }

  render() {
    return (
      <div>
        <div id="navbar-cont" tabIndex="-1">
          <nav className="navbar" id="navbar">
            <div className="uplogo">
              <a href="/home">
                <img src={iconLogo} alt="" />
              </a>
            </div>
            <ul className="navbar-nav" id="navbar-nav" onClick={this.toggle}>
              {" "}
              {this.props.children}{" "}
            </ul>
          </nav>
        </div>

        <div className="hamburger-block" onClick={this.toggle}>
          <div className="hamburger">
            <svg className="ham" viewBox="0 0 100 100" width="15vw" id="ham">
              <path
                className="line top"
                d="m 70,33 h -40 c 0,0 -8.5,-0.149796 -8.5,8.5 0,8.649796 8.5,8.5 8.5,8.5 h 20 v -20"
              />
              <path className="line" d="m 70,50 h -40" />
              <path
                className="line bottom"
                d="m 30,67 h 40 c 0,0 8.5,0.149796 8.5,-8.5 0,-8.649796 -8.5,-8.5 -8.5,-8.5 h -20 v 20"
              />
            </svg>
          </div>
          <div className="expand-container">
            <div id="expandable"></div>
          </div>
        </div>
      </div>
    );
  }
}

function NavItem(props) {
  return (
    <li className="nav-item">
      <a href={props.link} className="element" {...props.download ? {download:true} : {}}>
        {props.title}
      </a>
    </li>
  );
}

export default NavbarBar;
export { NavItem };
