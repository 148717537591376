import React from 'react';

const week = [{ day: "Lunedì", hours: "8:00 - 20:00" }, { day: "Martedì", hours: "8:00 - 20:00" }, { day: "Mercoledì", hours: "8:00 - 20:00" }, { day: "Giovedì", hours: "8:00 - 20:00" }, { day: "Venerdì", hours: "8:00 - 20:00" }, { day: "Sabato", hours: "8:00 - 20:00" }, { day: "Domenica", hours: "chiuso" }]
const weekDays = ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'];
const now = new Date();
const today = weekDays[now.getDay()];

class Timeline extends React.Component {
    constructor(props) {
        super(props);
        this.checkDay = this.checkDay.bind(this);
    }

    checkDay(day) {
        if (day === today) {
            return { backgroundColor: "#0b7051", color: "white" }
        }
    }


    render() {
        return (
            <div>
                <div className="info-table">
                    <div className="info-title">
                        <h1>Orari di apertura</h1><br />
                    </div>
                    <table className="table">
                        <tbody>
                            {
                                week.map((day, i) => {
                                    return <tr key={i} style={this.checkDay(day.day)}>
                                        <td>{day.day}</td>
                                        <td>{day.hours}</td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>
                    {this.checkDay()}
                </div>
            </div>
        );
    }
};

export default Timeline; 