import React from 'react';
import decor from '../img/decor.jpg';

class Presentation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
        };
        this.showEverything = this.showEverything.bind(this);
    }

    showEverything() {
        document.getElementById("readall").style.display ="none";
        this.setState({active: true});
    }
    render() {
        return (
            <div className="presentation" id="description">
                <div className="description">
                    <h1>Chi siamo</h1><br/>
                    <p>Verdealberi è specializzata nella realizzazione di lavori in quota su fune, sia nel settore Treeclimbing che in Artificiale. Da anni si occupa di gestione del verde, di consulenza e formazione professionale, di vendita di prodotti e attrezzature per il settore.</p><br/>
                    <p id="readall" onClick={this.showEverything} style={{cursor: "pointer", fontWeight: "bold"}}>Leggi tutto <i className="fa fa-caret-down"></i></p>
                    <p><span className={!this.state.active ? 'hidden' : 'visible'}>Nel 2013, Andrea Moriconi decide di affiancare all’esperienza acquisita come treeclimber, l’attività di lavori in quota in artificiale, la formazione, rivolta ai giovani ma anche ai professionisti, sui temi dell’arboricoltura, treeclimbing e sicurezza sul lavoro, e la vendita online di attrezzature e prodotti specifici. Verdealberi è distributore per l’Italia di FreeWorker e revisore certificato dei DPI anticaduta Petzl.<br/><br/>
        
                    Grazie al suo team di professionisti, Verdealberi è in grado di offrire al cliente un servizio completo, rapido e altamente qualificato.</span></p><br/><br/>
                    
                    <div className="btn">
                        <button type="button" className="button-lgt">
                            <a href="/about"><h2>Richiedi ora la tua consulenza gratuita</h2></a>
                        </button>
                    </div>
                </div>
                <img src={decor} alt="" />
            </div>
        );
    }
  }
  
  export default Presentation;
  