import React from 'react';
import verdeLogo from '../img/verdelogo.svg';
import leaves from '../img/leaves.png';

function Info() {
    return (
      <div>
        <div className="contacts-info">
          <img id="contacts-logo" src={verdeLogo} alt=""/>
          <div className="info">
            <h2>Via Alfonso Barcaccia 5 <br/> Ponte Valleceppi - Perugia (PG)</h2><br/><br/>
            <div className="line"><i className="fas fa-phone"/>
            <p>+39 393 268 7999</p></div>
            <div className="line"><i className="fas fa-envelope"/>
            <p>verdealberi@gmail.com</p></div>
          </div>
          <img id="contacts-back" src={leaves} alt=""/>
        </div>
      </div>
    );
  }
  
  export default Info;
  