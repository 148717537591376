import React from 'react';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import NavbarBar, {NavItem} from "./components/Navbar"
import Banner from "./components/Banner"
import Presentation from "./components/Presentation"
import Services from "./components/Services"
import Links from "./components/Links"
import SideProject from "./components/SideProject"
import Info from "./components/Info"
import Timeline from "./components/Timeline"
import Footer from "./components/Footer"

function Home() {
  return (
    <div style={{backgroundColor: "#E5FFEC"}}>
      <Banner/>
      <Presentation/>
      <Services/>
      <Links/>
      <SideProject/>
    </div>
  );
}

function About() {
  return (
    <div style={{backgroundColor: "#1f1f1f"}}>
      <Info/>
      <Timeline/>
    </div>
  );
}

function Error() {
  return (
    <div style={{backgroundColor: "#E5FFEC", height: "38vw"}}>
      <h1 style={{padding: "10vw"}}>Pagina non disponibile</h1>
    </div>
  );
}



function App() {
    return (
      <div className="bodydiv">
      <Router> 
        <NavbarBar>
          <NavItem title="Conf. drenaLINE" link="/files/Dichiarazione.docx" download/> 
          <NavItem title="Chi siamo" link="/home#description"/>
          <NavItem title="Servizi" link="/home#services"/>
          <NavItem title="Negozio" link="/home#links"/>
          <NavItem title="Contatti" link="/about"/>
        </NavbarBar>
        <Switch>
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>
          <Route path="/home" component={Home} />
          <Route path="/about" component={About} />
          <Route component={Error} />
        </Switch>
        <Footer/>
      </Router>
      </div>
    );
}

export default App;
