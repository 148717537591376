import React from 'react';
import verdeLogo from '../img/verdelogo.svg';

function Banner() {
    return (
      <div className="container">
        <div className="overlay" />
        <div className="slogan">
        <img src={verdeLogo} alt=""/>
        </div>
        <div className="logo-central"></div>
      </div>
    );
  }
  
  export default Banner;
  