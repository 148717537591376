import React from 'react';

function Services() {
    return (
        <div className="card1">
            <div className="services" id="services">
                <div className="text1">
                    <h1>I nostri servizi</h1><br/>
                    <p>I nostri servizi spaziano dalla gestione del verde, in treeclimbing e non, ai lavori in quota in ambito edilizio.</p><br/>
                    <p>Verdealberi è partner di Alberi Maestri per la formazione e l’aggiornamento professionale certificati. Su verdealberi.com puoi trovare tutti i migliori prodotti e le più moderne attrezzature per i lavori in quota su fune, interventi forestali e gestione del verde.</p><br/>
                    <h2>I nostri servizi in treeclimbing sono:</h2><br/>
                    <p>- abbattimento alberi <br/>
                    - potatura <br/>
                    - valutazioni di stabilità <br/>
                    - consolidamenti statici e dinamici della pianta <br/>
                    - cura e trattamento fitosanitario <br/>
                    - gestione del verde privato e pubblico <br/>
                    - realizzazione tavole dagli alberi abbattuti direttamente in loco <br/><br/>
                    </p>
                    <h2>In ambito edilizio i professionisti Verdealberi sono in grado di effettuare:</h2><br/>
                    <p>- montaggio linee vita <br/>
                    - messa in sicurezza e pulizia di cornicioni e grondaie <br/>
                    - montaggio barriere antipiccione <br/>
                    - pulizia vetrate e grondaie <br/>
                    - affissioni pubblicitarie <br/><br/>
                    </p>

                    <div className="btn">
                        <button type="button" name="button" className="button-drk">
                            <a href="/about"><h2>Richiedi un preventivo</h2></a>
                        </button>
                    </div>
                </div>
            </div>
            <div className="services-background">
            </div>
        </div>
    );
  }
  
  export default Services; 