import React from 'react';

function Links() {
    return (
        <div className="card2">
            <div className="links-background"/>
            <div className="links" id="links">
                <div className="text2">
                    <h1>E-commerce attrezzature da lavoro</h1><br/>
                    <p>Acquista su verdealberi.com <br/>
                    È facile, rapido e sicuro. Ordini e, in pochi giorni, ricevi direttamente nella tua sede i materiali acquistati. <br/>
                    Verdealberi è sinonimo di qualità, risparmio e assistenza professionale.</p><br/>

                    <div className="btn">
                        <button type="button" name="button" className="button-lgt">
                            <a href="https://www.verdealberi.com" target="_blank" rel="noopener noreferrer"><h2>Vai al sito</h2></a>
                        </button>
                    </div> <br/><br/>

                    <h1>Formazione professionale</h1>
                    <p>In collaborazione con Alberi Maestri Srl, centro di Formazione professionale e Servizi in arboricoltura, treeclimbing e sicurezza sul lavoro, effettuiamo corsi di formazione eaggiornamento certificati.</p><br/><br/>

                    <div className="btn">
                        <button type="button" name="button" className="button-lgt">
                            <a href="https://www.alberimaestri.com/formazione/calendario-corsi.html" target="_blank" rel="noopener noreferrer"><h2>Consulta il calendario dei corsi</h2></a>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
  }
  
  export default Links;