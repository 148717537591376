import React from 'react';

function SideProject() {
    return (
        <div className="card3">
            <div className="side-project">
                <div className="text3">
                    <h1>Legno in città</h1><br/>
                    <p> Il progetto “Legno in città” nasce dalla volontà di alcuni professionisti del verde di non mandare sprecato il legno degli alberi abbattuti, ma anzi di ridargli una seconda vita, valorizzandolo. Troppo spesso, infatti, i tronchi e i rami degli alberi abbattuti vengono utilizzati come legna da ardere o come cippato, bruciando letteralmente il loro potenziale, mentre se adeguatamente trattati potrebbero diventare una risorsa assai ben più redditizia e utile. Non dimentichiamo, inoltre, che tagliare gli alberi significa, soprattutto se ne facciamo cippato,liberare carbonio nell’atmosfera, una conseguenza che -vista la precarietà del nostro pianeta e l’emergenza causata dai cambiamenti climatici- non possiamo più permetterci. Al contrario, quando utilizziamo un albero abbattuto per farne un oggetto, il carbonio resta per la maggior parte all’interno del legno senza disperdersi nell’aria. Un’attività, dunque, delle tutto sostenibile che, al contempo, ci permette di avere qualcosa di bello da ammirare e utilizzare, che arricchisce la nostra casa, il nostro giardino e la nostra vita. <br/><br/>
                    Per questo “Legno in città” intende promuovere il riutilizzo di legno di qualità derivante dagli alberi abbattuti per farne prodotti di artigianato, anche artistico, prodotti di design, accessori per la casa e arredamento da esterno sia per il privato che per il pubblico.<br/><br/>
                    L’obiettivo di “Legno in città” è anche quello di creare una rete di professionisti -treeclimber, manutentori del verde, giardinieri, arboricoltori, agronomi, falegnami, artigiani, artisti, designer, commercianti, tecnici esperti privati e delle pubbliche amministrazioni, ambientalisti, vivaisti- che operino, a vari livelli, nella promozione e sensibilizzazione delle opportunità offerte dal legname urbano, attraverso il suo recupero, la sua valorizzazione e distribuzione.</p>
                </div>
            </div>
            <div className="side-background"/>
        </div>
    );
  }
  
  export default SideProject;
  