import React from 'react';

function Footer(props) {
  return (
    <div className="footer">
      <div className="footer-info">
        <p>Verdealberi<br/>
        verdealberi@gmail.com<br/>
        Via Alfonso Barcaccia, 5<br/>
        06134 Perugia (PG)<br/>
        P.Iva 03589630544</p>
        </div>
    </div>
  );
}

export default Footer;
